<template>
	<div class="Auth mx-auto">
		<v-stepper v-model="activeStep" elevation="0" class="pa-0 full-width">
			<v-stepper-items>
				<v-stepper-content step="1" class="pa-1">
					<validation-observer ref="observer" v-slot="{ valid }">
						<v-card
							max-width="400"
							class="d-flex align-start flex-column mx-auto"
							elevation="0"
						>
							<v-card-title class="px-0 pt-0">
								<h1 class="heading">휴대폰 번호 인증</h1>
							</v-card-title>
							<v-card-subtitle class="pl-0 text-base">
								사용하시는 휴대폰 번호를 인증하신 후,
								<br />
								시험 응시가 가능합니다
							</v-card-subtitle>
							<v-row no-gutters>
								<v-col
									cols="12"
									md="12"
									sm="12"
									:class="isMdAndUp ? 'pr-1' : 'pr-0'"
								>
									<v-select
										v-model="countryCode"
										:items="countryCodes"
										item-text="name"
										item-value="value"
									/>
								</v-col>
								<v-col md="9" sm="9" class="pr-1">
									<validation-provider
										v-slot="{ errors }"
										name="phone"
										rules="required|phone"
									>
										<v-text-field
											v-model="phoneNumber"
											:error-messages="errors"
											@keypress="allowOnlyNumber($event)"
											label="휴대폰 번호"
										/>
									</validation-provider>
								</v-col>
								<v-col md="3" sm="3" class="pt-2">
									<v-btn
										@click="generatePhoneNumberAuthCode"
										:disabled="(phoneNumber ? false : true) || !valid"
										color="primary"
										class="verification-btn"
									>
										인증요청
									</v-btn>
								</v-col>
							</v-row>
						</v-card>
					</validation-observer>
				</v-stepper-content>
				<v-stepper-content step="2" class="pa-1">
					<validation-observer ref="observer" v-slot="{ valid }">
						<v-card
							max-width="400"
							class="d-flex align-start flex-column mx-auto"
							elevation="0"
						>
							<v-card-title class="px-0 pt-0">
								<h1 class="heading">휴대폰 번호 인증</h1>
							</v-card-title>
							<v-card-subtitle class="pl-0 text-base">
								사용하시는 휴대폰 번호를 인증하신 후,
								<br />
								시험 응시가 가능합니다
							</v-card-subtitle>
							<v-row no-gutters style="width: 100%">
								<v-col md="9" sm="9" class="pr-1">
									<validation-provider name="code" rules="required">
										<v-text-field
											v-model="validateCode"
											ref="validateCodeInput"
											type="text"
											label="인증 번호"
										/>
									</validation-provider>
								</v-col>
								<v-col md="3" sm="3" class="pt-2">
									<v-btn
										@click="validatePhoneNumberAuthCode"
										:disabled="(validateCode ? false : true) || !valid"
										color="primary"
										class="verification-btn"
									>
										인증확인
									</v-btn>
								</v-col>
							</v-row>
						</v-card>
					</validation-observer>
				</v-stepper-content>
				<v-stepper-content step="3" class="pa-1">
					<v-card
						max-width="400"
						class="d-flex align-start flex-column mx-auto"
						elevation="0"
					>
						<v-card-title>
							<v-icon style="position: relative; bottom: 2px">
								mdi-calendar-check-outline
							</v-icon>
							<span class="ml-2">시험 스케쥴</span>
						</v-card-title>
						<v-divider />
						<v-card-text class="pt-0">
							<v-radio-group hide-details v-model="radioGroup" mandatory>
								<v-radio
									v-for="(schedule, index) in schedules"
									:key="'schedule-' + index"
									:value="schedule"
								>
									<template v-slot:label>
										<div>
											응시시작: {{ dateSiFormat(schedule.beginDatetime) }}
										</div>
										<div>
											응시종료: {{ dateSiFormat(schedule.endDatetime) }}
										</div>
									</template>
								</v-radio>
							</v-radio-group>
						</v-card-text>
						<v-card-actions style="width: 100%">
							<v-btn
								color="accent"
								class="full-width"
								:loading="isLoading"
								:disabled="isLoading"
								@click="selectSchedule"
								large
							>
								시험 스케쥴 선택하기
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</div>
</template>

<script>
import {
	ref,
	reactive,
	computed,
	nextTick,
	onMounted,
} from '@vue/composition-api'

import dayjs from 'dayjs'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { convertUTC2KST, dateSiFormat } from '@/filter'
import { krPhoneFormat, internationalPhoneFormat } from '@/filter'

import AuthService from '@/services/AuthService'
import MarketLicenseService from '@/services/MarketLicenseService'
import { infoSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

const EXAM_MINUTES = 70
export default {
	components: {},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const radioGroup = ref(null)
		const firebaseUser = computed(() => store.getters['auth/getFirebaseUser'])
		const activeStep = ref(1)
		const schedules = ref([])
		const isLoading = ref(false)
		const userSchedule = ref(null)
		const courseId = route.value.params.courseId
		const isMockExam = route.value.query.isMockExam
			? JSON.parse(route.value.query.isMockExam)
			: false
		const profile = computed(() => store.getters['user/getMeDetail'])
		const validateCode = ref('')
		const countryCode = ref('82')
		const validateCodeInput = ref(null)
		const countryCodes = reactive([
			{
				name: '대한민국+82',
				value: '82',
			},
		])
		const phoneNumber = ref(krPhoneFormat(profile.value.phoneNumber))

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const getLicenseExamUserSchedule = async () => {
			try {
				userSchedule.value =
					await MarketLicenseService.getLicenseExamUserSchedule({
						courseId: courseId,
						firebaseUid: firebaseUser.value.uid,
					})
			} catch (e) {
				console.error(e)
			}
		}

		const getLicenseExamSchedule = async () => {
			try {
				const _schedules = await MarketLicenseService.getLicenseExamSchedule(
					courseId,
				)

				for (const [index, schedule] of _schedules.entries()) {
					schedules.value.push({
						id: index,
						beginDatetime: convertUTC2KST(schedule.beginDatetimeUtc),
						endDatetime: convertUTC2KST(schedule.endDatetimeUtc),
						workbookId: isMockExam
							? schedule.mockWorkbookId
							: schedule.workbookId,
					})
				}
			} catch (e) {
				console.error(e)
			}
		}

		const getLicenseExamUserWorkbook = async () => {
			try {
				const data = await MarketLicenseService.getLicenseExamUserWorkbook({
					courseId: courseId,
					firebaseUid: firebaseUser.value.uid,
				})

				if (data && data.phoneVerified && !data.finished) {
					router.push({
						name: 'mypage-license-exam',
						params: {
							courseId: courseId,
						},
					})
					return
				}
			} catch (e) {
				console.error(e)
			}
		}

		const generatePhoneNumberAuthCode = async () => {
			try {
				const payload = {
					phoneNumber: internationalPhoneFormat(phoneNumber.value),
				}
				await AuthService.generatePhoneNumberAuthCode(payload)

				activeStep.value = 2
				nextTick(() => {
					validateCodeInput.value.$refs.input.focus()
				})
			} catch (e) {
				console.error(e)
			}
		}

		const validatePhoneNumberAuthCode = async () => {
			try {
				const payload = {
					code: validateCode.value,
					phoneNumber: internationalPhoneFormat(phoneNumber.value),
				}
				await AuthService.validatePhoneNumberAuthCode(payload)
				const confirm = await successSwal({
					html: '본인인증이 완료되었습니다',
					allowOutsideClick: false,
				})
				if (confirm.isConfirmed) {
					activeStep.value = 3
				}
			} catch (e) {
				warningSwal({
					html:
						e.response.status === 400
							? e.response.data.message
							: '인증하는데 문제가 발생되었습니다',
					allowOutsideClick: true,
				})
			}
		}

		const selectSchedule = async () => {
			try {
				isLoading.value = true
				const payload = {
					courseId: courseId,
					firebaseUid: firebaseUser.value.uid,
					scheduleIndex: radioGroup.value.id,
					workbookId: radioGroup.value.workbookId,
					startDatetimeUtc: dayjs().utc().format(),
					endDatetimeUtc: dayjs().utc().add(EXAM_MINUTES, 'm').format(),
				}

				if (isMockExam) {
					payload.mockExam = true
				}

				await MarketLicenseService.postLicenseExamUserWorkbook({
					courseId: courseId,
					firebaseUid: firebaseUser.value.uid,
					finished: false,
					mockExam: false,
					phoneVerified: true,
				})
				await MarketLicenseService.postLicenseExamUserSchedule(payload)

				const routeConfirm = await infoSwal({
					html: '시험을 시작합니다',
					confirmButtonText: '시작',
				})

				if (routeConfirm.isConfirmed) {
					setTimeout(
						() =>
							router.push({
								name: 'mypage-license-exam',
								params: {
									courseId: courseId,
								},
							}),
						800,
					)
				}
			} catch (e) {
				console.log(e)
			}
		}

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		onMounted(async () => {
			await getLicenseExamUserWorkbook()
			await getLicenseExamSchedule()
			await getLicenseExamUserSchedule()
		})

		store.dispatch('app/setIsLogoWhite', false)

		return {
			isMdAndUp,
			courseId,
			schedules,
			radioGroup,
			phoneNumber,
			countryCode,
			countryCodes,
			activeStep,
			isLoading,
			validateCode,
			validateCodeInput,

			dateSiFormat,

			selectSchedule,
			allowOnlyNumber,
			generatePhoneNumberAuthCode,
			validatePhoneNumberAuthCode,
			getLicenseExamSchedule,
			getLicenseExamUserSchedule,
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.v-input--selection-controls .v-radio > .v-label {
		display: block;
	}
}

.container {
	height: 100vh;
	background-color: white;
}

.full-width {
	width: 100%;
	max-width: 100%;
}

.Auth {
	height: 100vh;
	margin-top: 10em;

	form {
		justify-content: center;
		text-align: center;

		.v-input {
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.v-card {
		padding-top: 44px;
		padding-left: 34px;
		padding-right: 34px;
		padding-bottom: 34px;
		border: 1px solid #e4e5ed;
		width: 100%;

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		.signup-form {
			width: 100%;
		}
	}

	h1.heading {
		font-size: 25px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-top: 0;
	}

	.span-text {
		color: #888;
		font-size: 14px !important;
		font-weight: 500 !important;
		cursor: pointer;
	}

	button {
		width: 100%;
		min-width: 100% !important;
		min-height: 40px;
		font-size: 14px !important;
	}
}
</style>
